import customAxios from '../customAxios';
import { ManufacturerOption, ManufacturerResponse } from './serviceModel';

export let manufacturers:ManufacturerOption[] = [];
export async function getManufacturers() {
    try {
        
      const response = await customAxios.get('softwarePackages/manufacturers');
      const manufacturersResponse: ManufacturerResponse = response?.data ?? {} ;
      const manufacturersData: ManufacturerOption[] = manufacturersResponse.manufacturers.map((manufacturer: any) => ({
          key: manufacturer.id, 
          text: manufacturer.name,
        }));
        manufacturers = manufacturersData
    } catch (err) {
      console.error('Error fetching manufacturers:', err);
    } 
}