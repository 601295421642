import {
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IDropdownStyles,
  PrimaryButton,
  DefaultButton,
  Spinner,
} from '@fluentui/react';
import React, { useEffect, useState } from 'react';
import customAxios from '../../customAxios';
import {
  releaseStageOptions,
  requestStatusOptions,
  status,
  tableHeaderMap,
} from './constants';
import {
  SoftwareRequest,
  SoftwareResponse,
} from './list-software-requests-model';
import './index.scss';
import MessageBar from '../../common/messageBar';
import { List } from '../List/details-list';
import { getUserId } from '../../auth';

const ListSoftwareRequests = () => {
  const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 300 } };
  const [dialogContent, setDialogContent] = useState({
    type: DialogType.normal,
    title: '',
    closeButtonAriaLabel: 'Close',
    subText: '',
  });
  const [requestStatus, setRequestStatus] = useState<
    string | undefined | number
  >('created');
  const [dialog, showDialog] = useState(false);
  const [requestItems, setRequestItems] = useState<SoftwareRequest[]>([]);
  const [continuationToken, setContinuationToken] = useState<string>('');
  const [isTableDataLoading,setIsTableDataLoading] = useState<boolean>(false)
  const [isFetchDisabled, setIsFetchDisabled] = useState<boolean>(false)
  const requestObj = {
    filterJson: {
      requestStatus: requestStatus,
    },
    limit: 10,
    continuationToken: continuationToken,
  };
  const initialMessageBarContent = {
    showMessageBar: false,
    message: '',
    type: 0,
  };

  const [softwareLists, setSoftwareLists] = useState<SoftwareResponse[]>([]);
  const [spinner, showSpinner] = useState<boolean>(false);
  const [messageBarContent, setMessageBarContent] = useState(
    initialMessageBarContent,
  );
  const [isFetchDataCalled, setIsFetchDataCalled] = useState<boolean>(true);
  const resetMessageBarContent = () =>
    setMessageBarContent({
      ...messageBarContent,
      ...initialMessageBarContent,
    });

  const userId = getUserId();

  const onDialogOK = () => {
    showDialog(false);
  };

  useEffect(() => {
    if (isFetchDataCalled)
    getSoftwareRequestButtonAction();
      setIsFetchDataCalled(false);
    }, [softwareLists]);

  const getSoftwareRequestButtonAction = async () => {
    if (requestStatus) {
      setIsFetchDisabled(true)
      if (softwareLists.length === 0) {
        setContinuationToken('');
      }
      setIsTableDataLoading(true)
      let requestItemsList: SoftwareRequest[] = [];
      await customAxios
        .get('/softwarePackages/requests', { params: requestObj })
        .then((response) => {
          requestItemsList = response?.data['items'];
          setRequestItems(requestItemsList);
          setContinuationToken(response.data['continuationToken']);
        })
        .catch((error) => {
          setDialogContent({
            ...dialogContent,
            title: 'Failed',
            subText: error?.response?.data?.error ? error.response.data.error : 'Get Software Request failed.',
          });
          showDialog(true);
          setIsFetchDisabled(false)
        });
      await fetchSoftwareDetails(requestItemsList);
    }
  };

  const fetchSoftwareDetails = async (softwareList: SoftwareRequest[]) => {
    if (requestStatus) {
      let softwareListItems: SoftwareResponse[] = [];
      for (let i = 0; i < softwareList.length; i++) {
        let software = softwareList[i];
        await customAxios
          .get(`/softwarePackages/${software.softwareId}`)
          .then((response) => {
            const softwareResponse = new SoftwareResponse();
            softwareResponse.fileName = response.data['fileName'];
            softwareResponse.softwareId = software.softwareId;
            softwareResponse.id = software['baseInfo'].id;
            softwareResponse.model = response.data['model'];
            softwareResponse.manufacturer = response.data['manufacturer'];
            softwareResponse.versionCode = response.data['versionCode'];
            softwareResponse.status = status[requestStatus];
            softwareResponse.errorMessage = software['errorInfo']?software['errorInfo']['errorMessage']:'NA'
            softwareResponse.componentType = response.data['componentType'];
            softwareResponse.createdBy = software['baseInfo'].createdBy
            softwareResponse.createdByUserName =
              software['baseInfo'].createdByUserName;
            softwareResponse.modifiedByUserName =
              software['baseInfo'].modifiedByUserName;
            try {
              JSON.parse(software.requestPayload);
              const releaseStageIndex: number = JSON.parse(
                software.requestPayload,
              )['releaseStage'];
              softwareResponse.releaseStage =
                releaseStageOptions[releaseStageIndex];
            } catch {
              softwareResponse.releaseStage = '';
            }

            softwareListItems.push(softwareResponse);
          })
          .catch((error) => {
            setDialogContent({
              ...dialogContent,
              title: 'Failed',
              subText: error?.response?.data?.error ? error.response.data.error : 'Get Software Request failed.',
            });
            showDialog(true);
          });
      }
      setIsFetchDisabled(false)
      await setSoftwareLists([...softwareLists,...softwareListItems]);
      await showSpinner(false);
      await setIsTableDataLoading(false)
    }
  };

  const fetchData = () => {
    setContinuationToken('');
    setRequestItems([]);
    setSoftwareLists([]);
    showSpinner(true);
    setIsFetchDataCalled(true);
  };

  useEffect(() => {
    if (isFetchDataCalled) {
      getSoftwareRequestButtonAction();
      setIsFetchDataCalled(false);
    }
  }, [requestItems]);

  const statusApproveAction = async (index: number, id: any) => {
    await customAxios
      .patch(`/softwarePackages/requests/approve/${id}`)
      .then(() => {
        softwareLists[index].status = status['approved'];
        setSoftwareLists([...softwareLists]);
        setDialogContent({
          ...dialogContent,
          title: 'Success',
          subText: 'Request Approved Successfully',
        });
      })
      .catch((error) => {
        setDialogContent({
          ...dialogContent,
          title: 'Failed',
          subText:  error?.response?.data?.error ? error.response.data.error : 'Unable to Approve Request',
        });
       softwareLists.splice(index,1)
      });
    showDialog(true)
  };

  const statusRejectAction = async (index: number, id: any) => {
    await customAxios
      .patch(`/softwarePackages/requests/reject/${id}`)
      .then(() => {
        softwareLists[index].status = status['rejected'];
        setSoftwareLists([...softwareLists]);
        setDialogContent({
          ...dialogContent,
          title: 'Success',
          subText: 'Request Rejected Successfully',
        });
      })
      .catch((error) => {
        setDialogContent({
          ...dialogContent,
          title: 'Failed',
          subText:  error?.response?.data?.error ? error.response.data.error :'Unable to Reject Request',
        });
        softwareLists.splice(index,1)
      });
    showDialog(true);
  };

  const loadMoreData = () => {
    if (continuationToken && continuationToken !== '') {
      getSoftwareRequestButtonAction();
    }
  };

  const columns = () => {
    const columns: any[] = [];
    const tableHeaderKeys = Object.keys(tableHeaderMap);
    tableHeaderKeys.forEach((item, index) => {
      if(requestStatus !== 'errored' && item === 'errorMessage'){
        return
      }
      if(requestStatus === 'created' && item === 'modifiedByUserName'){
        return
      }
      columns.push({
        key: 'column' + index,
        name: tableHeaderMap[item],
        fieldName: item,
        minWidth: item === 'status' && requestStatus === 'created' || item === 'errorMessage' ? 200 : 100,
        maxWidth: item === 'status' && requestStatus === 'created' || item === 'errorMessage' ? 200 : 100,
        isResizable: true,
      });
    });
    return columns;
  };
  const renderItems = () => {
    if (softwareLists.length > 0) {
      let items: any[] = [];
      softwareLists.forEach((item: SoftwareResponse, index) => {
        items.push({
          id: item.id,
          name: item.fileName,
          manufacturer: item.manufacturer,
          model: item.model,
          versionCode: item.versionCode,
          componentType: item.componentType,
          releaseStage: item.releaseStage,
          createdBy: item.createdBy,
          createdByUserName: item.createdByUserName,
          modifiedByUserName: item.modifiedByUserName,
          status: item.status,
          errorMessage: item.errorMessage
        });
      });
      return items;
    } else return [];
  };

  const customCellRender = (software: any, index: any, column: any) => {
    if (column.fieldName == 'status') {
      return software.status === 'Created' ? (
        <>
          <PrimaryButton
            className='statusButton'
            disabled={userId === software.createdBy}
            text='Approve'
            onClick={() => statusApproveAction(index, software.id)}
          />
          <DefaultButton
            className='statusButton'
            disabled={userId === software.createdBy}
            text='Reject'
            onClick={() => statusRejectAction(index, software.id)}
          />
        </>
      ) : (
        <div className='status'>{status && [software.status]}</div>
      );
    } else {
      return software[column.fieldName];
    }
  };

  return (
    <React.Fragment>
      {messageBarContent.showMessageBar && (
        <MessageBar
          message={messageBarContent.message}
          messageBarType={messageBarContent.type}
          onDismiss={resetMessageBarContent}
        />
      )}
      <div className='container'>
        <h2>Approvals</h2>
        <div className="softwareRequestListContainer">
        <Dropdown
          label='Request Status'
          defaultSelectedKey="created"
          options={requestStatusOptions}
          styles={dropdownStyles}
          onChange={(_e, option) => 
            {
              setRequestStatus(option?.key)
              setIsFetchDataCalled(true);
              setSoftwareLists([])
            }
          }
          disabled={isFetchDisabled}
        />
        <PrimaryButton
          className='buttonContainer'
          disabled={!requestStatus || isFetchDisabled}
          text='Refresh'
          onClick={fetchData}
        />
        </div>
        {dialog && (
          <Dialog
            hidden={!dialog}
            onDismiss={() => showDialog(false)}
            dialogContentProps={dialogContent}
          >
            <DialogFooter>
              <PrimaryButton onClick={onDialogOK} text='Ok' />
            </DialogFooter>
          </Dialog>
        )}
        {spinner ? (
          <div className='spinner'>
            <Spinner />
          </div>
        ) : (
          <div>
            <h3 className='results-container'>Result</h3>
            {/* <table>
            <thead>
              <tr>
                {tableHeaderOptions.map((option) => (
                  <th>{option}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {softwareLists.length > 0 &&
                softwareLists.map((software, index) => {
                  return (
                    <tr>
                      <td>{software.fileName}</td>
                      <td>{software.manufacturer}</td>
                      <td>{software.model}</td>
                      <td>{software.componentType}</td>
                      <td>{software.versionCode}</td>
                      <td>{software.releaseStage}</td>
                      <td className="statusColumn">
                        {software.status === "Created" ? (
                          <>
                            <PrimaryButton
                              className="statusButton"
                              disabled={false}
                              text="Approve"
                              onClick={() =>
                                statusApproveAction(index, software.id)
                              }
                            />
                            <DefaultButton
                              className="statusButton"
                              disabled={false}
                              text="Reject"
                              onClick={() =>
                                statusRejectAction(index, software.id)
                              }
                            />
                          </>
                        ) : (
                          <div className="status">{status && [software.status]}</div>
                        )}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table> */}
            <List
              items={renderItems()}
              columns={columns()}
              onRenderItemColumn={(item: any, index: any, column: any) =>
                customCellRender(item, index, column)
              }
              onScroll={() => {
                loadMoreData();
              }}
              isTableDataLoading={isTableDataLoading}
            />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default ListSoftwareRequests;
