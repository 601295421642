import {
  Dialog,
  DialogFooter,
  DialogType,
  Dropdown,
  IColumn,
  IDropdownStyles,
  PrimaryButton,
  Selection,
  Separator
} from "@fluentui/react";
import { useEffect, useState } from "react";
import customAxios from "../../customAxios";
import { List } from "../List/details-list";
import { SoftwareJobsDetailsResponseModel } from "../data-model/software-job-response-model";
import {
  jobStatus,
  jobTableHeaderMap,
  requestStatusOptions,
  taskStateTableHeaderMap,
} from "./constants";
import "./index.scss";
export interface IDocument {
  jobId: string;
}
const SoftwareUploadJobManagement = () => {
  const dropdownStyles: Partial<IDropdownStyles> = { dropdown: { width: 300 } };
  const [isTableDataLoading, setIsTableDataLoading] = useState<boolean>(false);
  const [softwareUploadJobResponseList, setSoftwareUploadJobResponseList] =
    useState<SoftwareJobsDetailsResponseModel[]>([]);
  const [continuationToken, setContinuationToken] = useState<string>("");
  const [isFetchDataCalled, setIsFetchDataCalled] = useState<boolean>(true);
  const [selectedJobId, setSelectedJobId] = useState<string>("");
  const [requestStatus, setRequestStatus] = useState<
    string | undefined | number
  >("InProgress");
  const [dialogContent, setDialogContent] = useState({
    type: DialogType.normal,
    title: "",
    closeButtonAriaLabel: "Close",
    subText: "",
  });

  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    if (isFetchDataCalled) {
      setIsTableDataLoading(true);
      getSoftwareUploadJobDetails();
      setIsTableDataLoading(false);
      setIsFetchDataCalled(false);
    }

    const interval = setInterval(() => {
      if (requestStatus === "InProgress") {
        if (softwareUploadJobResponseList.length > 0) {
          getSoftwareUploadJobDetailsById();
        } else {
          getSoftwareUploadJobDetails();
        }
      }
    }, 60000);
    return () => clearInterval(interval);
  }, [softwareUploadJobResponseList]);

  const getSoftwareUploadJobDetailsById = async () => {
    let updatedJobData: SoftwareJobsDetailsResponseModel[] = [];

    for (let i = 0; i < softwareUploadJobResponseList.length; i++) {
      let jobId = softwareUploadJobResponseList[i].baseInfo.id;
      await customAxios
        .get(`softwareManagementJobs/${jobId}`)
        .then((response) => {
          updatedJobData.push(response.data);
        }).catch((error) => {
          setDialogContent({
            ...dialogContent,
            title: "Failed",
            subText: error?.response?.data?.error ? error.response.data.error : 'Failed to fetch data. Please try again later.',
          });
          setShowDialog(true);
        });
    }
    setSoftwareUploadJobResponseList(updatedJobData);
  };

  const getSoftwareUploadJobDetails = async () => {
    const requestBody = {
      JobName: "UploadJobExecutor",
      JobStatus: requestStatus,
    };
    const payload = {
      filterJson: {
        ...requestBody,
      },
      continuationToken: continuationToken,
      limit: 10,
    };
    await customAxios
      .get("softwareManagementJobs", { params: payload })
      .then((response) => {
        setSoftwareUploadJobResponseList([
          ...softwareUploadJobResponseList,
          ...response.data["items"],
        ]);
        setContinuationToken(response.data["continuationToken"]);
      }).catch((error) => {
        setDialogContent({
          ...dialogContent,
          title: "Failed",
          subText: error?.response?.data?.error ? error.response.data.error : 'Failed to fetch data. Please try again later.',
        });
        setShowDialog(true);
      });;
  };

  const loadMoreData = () => {
    if (continuationToken && continuationToken !== "") {
      getSoftwareUploadJobDetails();
    }
  };

  const getSelectionJobId = () => {
    return (selection.getSelection()[0] as IDocument)?.jobId;
  };

  const selection: Selection = new Selection({
    onSelectionChanged: () => setSelectedJobId(getSelectionJobId),
  });

  const renderItems = () => {
    if (softwareUploadJobResponseList.length > 0) {
      let tableItems: any[] = [];
      softwareUploadJobResponseList.forEach(
        (item: SoftwareJobsDetailsResponseModel, index) => {
          const input = JSON.parse(item.input);
          const jobStatusTextClass =
            item.status === "Failed"
              ? "jobFailed"
              : item.status === "Completed"
              ? "jobCompleted"
              : "jobInProgress";
          const jobCreatedDate =
            item.baseInfo?.createdAt &&
            new Date(item.baseInfo.createdAt * 1000).toLocaleString();
          tableItems.push({
            jobId: item.baseInfo.id,
            deviceType: input.deviceType,
            manufacturer: input.manufacturer,
            model: input.model,
            componentType: input.componentType,
            jobStatus: (
              <span className={jobStatusTextClass}>{jobStatus[item.status]}</span>
            ),
            jobCreatedAt: jobCreatedDate,
          });
        }
      );
      return tableItems;
    } else return [];
  };

  const TaskDetailsComponent = () => {
    const jobDetails = softwareUploadJobResponseList.find(
      (item) => item.baseInfo.id === selectedJobId
    );

    const renderTaskStatusItems = () => {
      let tableItems: any[] = [];
      if (jobDetails && jobDetails.taskGroups) {
        const taskDetails = jobDetails.taskGroups.flat();
        taskDetails.forEach((task, index) => {
          tableItems.push({
            taskName: task.name,
            taskCreatedAt:
              task.createdAt &&
              new Date(task.createdAt * 1000).toLocaleString(),
            taskStartedAt:
              task.startedAt &&
              new Date(task.startedAt * 1000).toLocaleString(),
            taskCompletedAt:
              task.completedAt &&
              new Date(task.completedAt * 1000).toLocaleString(),
            taskStatus: task.taskStatus,
            message: task.message,
          });
        });
        return tableItems;
      } else return [];
    };
    const taskStatusColumns = () => {
      const columns: IColumn[] = [];
      const tableHeaderKeys = Object.keys(taskStateTableHeaderMap);
      tableHeaderKeys.forEach((item, index) => {
        columns.push({
          key: "column" + index,
          name: taskStateTableHeaderMap[item],
          fieldName: item,
          minWidth: item === "message" ? 200 : 120,
          isMultiline: item === "message",
          isResizable: true,
        });
      });
      return columns;
    };

    return (
      <List
        items={renderTaskStatusItems()}
        columns={taskStatusColumns()}
        isTableDataLoading={isTableDataLoading}
      />
    );
  };

  const JobDetailsComponent = () => {
    const jobDetails = softwareUploadJobResponseList.find(
      (item) => item.baseInfo.id === selectedJobId
    );

    if (jobDetails && jobDetails.input) {
      const inputDetails = JSON.parse(jobDetails.input);

      return (
        <table className="jobDetailsTable">
          <tr className="jobDetailsRow">
            <td className="jobDetailsNameColumn">File Name</td>
            <td className="jobDetailsColumn">{inputDetails.fileName}</td>
          </tr>
          <tr className="jobDetailsRow">
            <td className="jobDetailsNameColumn">File Version Name</td>
            <td className="jobDetailsColumn">{inputDetails.versionName}</td>
          </tr>
          <tr className="jobDetailsRow">
            <td className="jobDetailsNameColumn">File Version Code</td>
            <td className="jobDetailsColumn">{inputDetails.versionCode}</td>
          </tr>
          <tr className="jobDetailsRow">
            <td className="jobDetailsNameColumn">File URL</td>
            <td className="jobDetailsColumn">{inputDetails.fileURL}</td>
          </tr>
          <tr className="jobDetailsRow">
            <td className="jobDetailsNameColumn">Description</td>
            <td className="jobDetailsColumn">{inputDetails.description}</td>
          </tr>
          <tr className="jobDetailsRow">
            <td className="jobDetailsNameColumn">Release Notes</td>
            <td className="jobDetailsColumn">{inputDetails.releaseNotes}</td>
          </tr>
          <tr className="jobDetailsRow">
            <td className="jobDetailsNameColumn">Minimum Firmware Version</td>
            <td className="jobDetailsColumn">
              {inputDetails.dependencyInfo?.firmware}
            </td>
          </tr>
          <tr className="jobDetailsRow">
            <td className="jobDetailsNameColumn">
              Minimum Company Portal Version
            </td>
            <td className="jobDetailsColumn">
              {inputDetails.dependencyInfo?.companyportal}
            </td>
          </tr>
          <tr className="jobDetailsRow">
            <td className="jobDetailsNameColumn">Minimum OEM Version</td>
            <td className="jobDetailsColumn">
              {inputDetails.dependencyInfo?.oemVersion}
            </td>
          </tr>
        </table>
      );
    }
    return null;
  };

  const CompletedJobDetailsComponent = () => {
    const completedJobDetails = softwareUploadJobResponseList.find(
      (item) => item.baseInfo.id === selectedJobId
    );

    if (completedJobDetails && completedJobDetails.output) {
      const outputDetails = JSON.parse(completedJobDetails.output);

      return (
        <table className="jobDetailsTable">
          <tr className="jobDetailsRow">
            <td className="jobDetailsNameColumn">Current Software Package Id</td>
            <td className="jobDetailsColumn">{outputDetails.CurrentSoftwarePackageId}</td>
          </tr>
          <tr className="jobDetailsRow">
            <td className="jobDetailsNameColumn">Previous Software Package Id</td>
            <td className="jobDetailsColumn">{outputDetails.PreviousSoftwarePackageId}</td>
          </tr>
          <tr className="jobDetailsRow">
            <td className="jobDetailsNameColumn">Device Type</td>
            <td className="jobDetailsColumn">{outputDetails.SoftwarePackage.deviceType}</td>
          </tr>
          <tr className="jobDetailsRow">
            <td className="jobDetailsNameColumn">Manufacturer</td>
            <td className="jobDetailsColumn">{outputDetails.SoftwarePackage.manufacturer}</td>
          </tr>
          <tr className="jobDetailsRow">
            <td className="jobDetailsNameColumn">Model</td>
            <td className="jobDetailsColumn">{outputDetails.SoftwarePackage.model}</td>
          </tr>
          <tr className="jobDetailsRow">
            <td className="jobDetailsNameColumn">Component Type</td>
            <td className="jobDetailsColumn">{outputDetails.SoftwarePackage.componentType}</td>
          </tr>
          <tr className="jobDetailsRow">
            <td className="jobDetailsNameColumn">File Name</td>
            <td className="jobDetailsColumn">{outputDetails.SoftwarePackage.fileName}</td>
          </tr>
          <tr className="jobDetailsRow">
            <td className="jobDetailsNameColumn">File URL</td>
            <td className="jobDetailsColumn">{outputDetails.SoftwarePackage.fileURL}</td>
          </tr>
          <tr className="jobDetailsRow">
            <td className="jobDetailsNameColumn">Version Name</td>
            <td className="jobDetailsColumn">{outputDetails.SoftwarePackage.versionName}</td>
          </tr>
          <tr className="jobDetailsRow">
            <td className="jobDetailsNameColumn">Version Code</td>
            <td className="jobDetailsColumn">{outputDetails.SoftwarePackage.versionCode}</td>
          </tr>
          {outputDetails.SoftwarePackage.releaseStages.DogFood && <tr className="jobDetailsRow">
            <td className="jobDetailsNameColumn">Dogfood Published Date</td>
            <td className="jobDetailsColumn">{outputDetails.SoftwarePackage.releaseStages.DogFood.publishDate}</td>
          </tr>}
          {outputDetails.SoftwarePackage.dependencyInfo?.firmware && <tr className="jobDetailsRow">
            <td className="jobDetailsNameColumn">Minimum Firmware Version</td>
            <td className="jobDetailsColumn">{outputDetails.SoftwarePackage.dependencyInfo.firmware}</td>
          </tr>}
          {outputDetails.SoftwarePackage.dependencyInfo?.companyportal && <tr className="jobDetailsRow">
            <td className="jobDetailsNameColumn">Minimum Company Portal Version</td>
            <td className="jobDetailsColumn">{outputDetails.SoftwarePackage.dependencyInfo.companyportal}</td>
          </tr>}
          {outputDetails.SoftwarePackage.dependencyInfo?.oem && <tr className="jobDetailsRow">
            <td className="jobDetailsNameColumn">Minimum OEM Version</td>
            <td className="jobDetailsColumn">{outputDetails.SoftwarePackage.dependencyInfo.oem}</td>
          </tr>}
          <tr className="jobDetailsRow">
            <td className="jobDetailsNameColumn">Release Notes</td>
            <td className="jobDetailsColumn">{outputDetails.SoftwarePackage.releaseNotes}</td>
          </tr>
          <tr className="jobDetailsRow">
            <td className="jobDetailsNameColumn">Description</td>
            <td className="jobDetailsColumn">{outputDetails.SoftwarePackage.description}</td>
          </tr>
          <tr className="jobDetailsRow">
            <td className="jobDetailsNameColumn">Granular config</td>
            <td className="jobDetailsColumn">{outputDetails.SoftwarePackage.granularConfig}</td>
          </tr>
        </table>
      );
    }
    return null;
  };

  const columns = () => {
    const columns: IColumn[] = [];
    const tableHeaderKeys = Object.keys(jobTableHeaderMap);
    tableHeaderKeys.forEach((item, index) => {
      columns.push({
        key: "column" + index,
        name: jobTableHeaderMap[item],
        fieldName: item,
        minWidth: 120,
        isResizable: true,
      });
    });
    return columns;
  };

  const refreshAction = () => {
    setIsFetchDataCalled(true);
    setSelectedJobId("");
    setSoftwareUploadJobResponseList([]);
  };

  const onDialogOK = () => {
    setShowDialog(false);
  };

  return (
    <div className="container">
      <h2>Software Upload Job Management</h2>
      <div className="jobDetailsHeaderContainer">
        <Dropdown
          label="Request Status"
          options={requestStatusOptions}
          styles={dropdownStyles}
          defaultSelectedKey="InProgress"
          onChange={(_e, option) => {
            setRequestStatus(option?.key);
            setIsFetchDataCalled(true);
            setSelectedJobId("");
            setSoftwareUploadJobResponseList([]);
          }}
          disabled={isFetchDataCalled}
        />
        <PrimaryButton
          className="buttonContainer"
          text="Refresh"
          onClick={refreshAction}
        />
      </div>
      <Separator />
      {showDialog && (
        <Dialog
          hidden={!showDialog}
          onDismiss={() => setShowDialog(false)}
          dialogContentProps={dialogContent}
        >
          <DialogFooter>
            <PrimaryButton onClick={onDialogOK} text="Ok" />
          </DialogFooter>
        </Dialog>
      )}
      {softwareUploadJobResponseList.length > 0 && (
        <List
          items={renderItems()}
          columns={columns()}
          onScroll={() => {
            loadMoreData();
          }}
          isTableDataLoading={isTableDataLoading}
          selection={selection}
          enableSelection={true}
        />
      )}

      {selectedJobId && (
        <>
          <Separator />
          <h4>Task Status</h4>
          <TaskDetailsComponent />
          <Separator />
          <h4>Job Details</h4>
          <JobDetailsComponent />
          {requestStatus === "Completed" && (
            <>
              <Separator />
              <h4>Job Output Details</h4>
              <CompletedJobDetailsComponent />
            </>
          )}
        </>
      )}
    </div>
  );
};

export default SoftwareUploadJobManagement;
